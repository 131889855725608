
import {Vue, Component, Ref} from "vue-property-decorator";
import {ListDataSource, PriceCatalogListDataSource} from "@/data/List/ListDataSource";
import FileUploaderDialog from "@/components/helpers/file-uploader-dialog.vue";
import DataList from "@/components/common/data/list.vue";
import {mdiDownload, mdiPlusCircleOutline, mdiUpload} from "@mdi/js";

@Component({
  components: {FileUploaderDialog, DataList}
})
export default class Home extends Vue {
  @Ref("dataList") dataList!: DataList;

  dataSource: PriceCatalogListDataSource = new PriceCatalogListDataSource({
      pageIndex: 1,
      pageSize: 15,
  });
  icons: any = {
    circlePlus: mdiPlusCircleOutline,
    upload: mdiUpload,
    download: mdiDownload,
  };
  folderConfig: any = {
    className: "priceCatalogFolder",
    rootFolder: "mainFolder",
  };

  uploaderDialogVisible: boolean = false;
  $message: any;

  async uploadFile(event: any) {
    try {
      console.log(event.data)
      await this.dataSource.uploadXml(event.data as FormData);
      await this.dataList.refreshData();
      this.uploaderDialogVisible = false;
      this.$message("Успешно загружено");
    } catch (error) {
      this.$message((error as any)?.response?.data, "error");
    }
  }


  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Статьи",
        disabled: true,
      },
    ];
  }
}
